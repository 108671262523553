/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";


import { useDispatch, useSelector } from 'react-redux';
import { open, close } from '../redux/popupSlice';

import * as api from '../libs/api';

export default function() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation(); 
    const [moveTopshow, setMoveTopshow] = useState(false);
    const [canClick, setCanClick] = useState(true);
    const [agree, setAgree] = useState({agree0:"",agree1:"",agree2:""});
    const handleScroll = () => {
        const scrollPercentage = (window.scrollY / (document.body.scrollHeight - window.innerHeight)) * 100;
        if (window.scrollY > 0) {
            setMoveTopshow(true);
        } else {
            setMoveTopshow(false);
        }
    };
    const goPageTop = () => {
        if (canClick) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setCanClick(false);
            setTimeout(() => {
                setCanClick(true);
            }, 2000);
        }
    };
    const popupFunc = (type) => {
        
        if(type === 'register_terms1') {
            dispatch(
                open({
                    title: "서비스 이용약관",
                    message: agree.agree0,
                    
                })
            );
        }

        if(type === 'register_terms2') {
            dispatch(
                open({
                    title: "개인정보 수집 및 이용동의",
                    message: agree.agree1,
                    
                })
            );
        }


    }
    useEffect(() => {

        api.getData("/agree", {}).then(res=>{
            if(res && res?.data?.status=="SUCCESS"){
                const ret = res.data.data;
                setAgree({agree0:ret.agree0,agree1:ret.agree1,agree2:ret.agree2});
            }
        });
    }, []);

        
    useEffect(() => {       
        
        window.addEventListener('scroll', handleScroll);
        return () => {
        
            window.removeEventListener('scroll', handleScroll);
        };
    })

    const openNaverSitePromise = (e) => {
        return new Promise((res) => {
          window.open(e.target.value, "_blank");
          res(true);
        });
      };
    
      const handleButtonPromise = (e) => {
        openNaverSitePromise(e).then(() => {
          window.open(e.target.value, "_blank");
        });
      };

    const onFamily = (e) => {
        
        
        var result = window.open(e.target.value, 'INFO', '');
        if (result == null || result == undefined) {
           alert('설정에 팝업차단을 허용해주시기 바랍니다.');
        }    
        
 
    }

    return (
        <div className="footer">
            
            <div className={`main_move_top ${moveTopshow ? 'show' : ''}`} onClick={goPageTop}>
                    <img src='/images/move-top-arrow.svg'/>
                </div>
            <div className="footer_part1">
                <div className="footer_top">
                    <div className="footer_top_sub">
                        <p style={{fontWeight: '700'}} onClick={() => { popupFunc('register_terms1') }}>이용약관</p>
                        <p style={{fontWeight: '700'}} onClick={() => { popupFunc('register_terms2') }}>개인정보처리방침</p>
                    </div>

                    <div className="footer_top_sub">
                        <select onChange={(e)=>onFamily(e)} value="">
                            <option value="">Family Site</option>
                            <option value="https://teacher.changbiedu.com/">창비교육연수원</option>
                            <option value="https://www.changbi.com/">창비</option>
                            <option value="http://www.mediachangbi.com/">미디어창비</option>
                            <option value="https://www.instagram.com/changbibusan/">창비부산</option>
                            <option value="https://switch.changbi.com/">스위치</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className="footer_part2">
                <div className="footer_btm">
                    <div className="footer_btm_box">
                       
                            <img src='/images/logo-footer.svg' width="216" onClick={()=>navigate(".")}/>
                        
                        <div className="text_box">
                            <p className="one">(주)창비교육</p>
                            <div className="btm_text pc">
                                대표 김종곤 | 사업자등록번호 105-88-04459<br />
                                04004 서울특별시 마포구 월드컵로12길 7<br />
                                Tel: 1833-7247 | E-Mail : edumaster@changbi.com
                            </div>

                            <div className="btm_text mobile" style={{display: 'none'}}>
                                대표 김종곤 | 사업자등록번호<br />105-88-04459<br />
                                04004 서울특별시 마포구 월드컵로12길 7<br />
                                Tel: 1833-7247<br />
                                E-Mail : edumaster@changbi.com
                            </div>

                            <p className="two">COPYRIGHT ⓒ CHANGBIEDU ALL RIGHT RESERVED</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}